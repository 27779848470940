import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { logger } from '~/shared/lib/logger'
import { Modal } from '../Modal'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '12px',
}

export function ReloadSw() {
  const [isLoading, toggleIsLoading] = useState<boolean>(false)
  const [sw, swSet] = useState<ServiceWorkerRegistration | undefined>()

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl: string, r: ServiceWorkerRegistration | undefined) {
      if (!r) return

      setInterval(async () => {
        if (r.installing || !navigator) return

        if ('connection' in navigator && !navigator.onLine) return

        try {
          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (resp?.status === 200) {
            await r.update()
            swSet(r)
          }
        } catch (e) {
          logger.error(e)
        }
      }, 60000)
    },
  })

  const handleReload = async () => {
    toggleIsLoading(true)
    await updateServiceWorker()

    setTimeout(async () => {
      await sw?.unregister()
      location.reload()
    }, 3000)
  }

  return (
    <Modal isShow={needRefresh}>
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography fontSize='24px' lineHeight='26px'>
            Доступна новая версия.
          </Typography>
          <Typography fontSize='16px' lineHeight='18px'>
            Обновите приложение для дальнейшего использования!
          </Typography>

          <LoadingButton
            variant='outlined'
            color='warning'
            fullWidth
            onClick={handleReload}
            loading={isLoading}
          >
            Обновить
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
